<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Grn List"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <DateQuerySetter @onClickGo="getGrns"/>
        </div>
      </div>
    </div>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/inventory/grn/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from 'vue-router';

const route = useRoute();
const router = useRouter();

function onClickAddNewButton() {
  router.push({name: `grn-add`, params: route.params, query: route.query})
}

function getGrns() {
  //todo
}

function onPageChange() {
  //todo
}

</script>

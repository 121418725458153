<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>GRN Date</th>
          <th>GRN No</th>
          <th>Party Name</th>
          <th>PO Ref.</th>
          <th>PO Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <td>12/3/22</td>
        <td>#f4545</td>
        <td>Party</td>
        <td>Ref.</td>
        <td>14/4/22</td>
        <td>Active</td>
        <td>View</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
